@import "../../../assets/styles/_var.scss";
@import "../../../assets/styles/_mixins.scss";
.loaderWrap {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: center;
  @include theme() {
    background: theme-get("loaderbgSection");
  }
  .ant-spin {
    @include theme() {
      color: theme-get("paragraph");
    }
  }
  .collection__frame {
    border-radius: 24px;
    padding: 24px;
    @media (max-width: 767px) {
      height: auto;
    }
    h3 {
      font-size: 18px;
      font-weight: 600;
      @include theme() {
        color: theme-get("titleColor");
      }
    }
    &__list {
      @media (max-width: 767px) {
        margin-top: 40px;
      }
      svg.moving_bar {
        width: 100%;
        max-width: 150px;
        animation: rotate 5s linear infinite;
      }
      circle {
        fill: none;
        @include theme() {
          stroke: theme-get("heighlightText");
        }
        stroke-width: 2px;
        stroke-dasharray: 300;
        animation: outline 2s cubic-bezier(0.77, 0, 0.18, 1) infinite;
      }
      &__svgpath {
        position: relative;
        text-align: center;
        &::after {
          position: absolute;
          left: 0;
          right: 0;
          margin: auto;
          content: "";
          top: 0;
          width: 150px;
          height: 150px;
          z-index: -1;
          border-radius: 50%;
          @include theme() {
            border: 5px solid theme-get("cardBg");
          }
        }
        .scrollbar-container {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .search_content {
          text-align: center;
          position:absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          button {
            border: 0;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            @include theme() {
              background: theme-get("lightshadebtn");
              color: theme-get("heighlightText")
            }
            svg {
              path {
                @include theme() {
                  fill: theme-get("heighlightText");
                }
              }
            }
          }
          p {
            margin-top: 8px;
            @include theme() {
              color: theme-get("navText");
            }
          }
        }
      }
    }
    @keyframes outline {
      0% {
        stroke-dashoffset: 0;
      }
      50% {
        stroke-dashoffset: 300;
      }
      100% {
        stroke-dashoffset: 600;
      }
    }
    @keyframes rotate {
      from {
        transform: rotate(0turn);
      }
      to {
        transform: rotate(-1turn);
      }
    }
  }
}
