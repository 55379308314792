@import "./assets/styles/_var.scss";
@import "./assets/styles/_mixins.scss";

.successToast {
  min-width: 350px;
  border-radius: 24px;
  overflow: hidden;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  box-shadow: 0px 1px 1px rgba(9, 13, 20, 0.4),
    -4px -4px 8px rgba(224, 224, 255, 0.04), 8px 8px 24px rgba(9, 13, 20, 0.4);

  @include theme {
    background: theme-get("toastBg");
    color: $white;
  }

  backdrop-filter: blur(54.3656px);

  .textSection {
    position: relative;
    padding-left: 16px;
    width: 100%;

    h5 {
      margin-bottom: 0px;
      font-weight: 600;
      font-size: 14px;
      line-height: normal;
      margin-bottom: 5px;
    }

    p {
      margin-bottom: 0px;
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
      overflow: hidden;
      color: rgba(224, 224, 255, 0.6);
      max-width: 253px;
      // white-space: nowrap;
      // text-overflow: ellipsis;
    }

    button {
      padding: 0;
      background: transparent;
      border: 0px solid;
      top: 0;
      position: absolute;
      right: 0;
      width: 18px;
      height: 18px;
      cursor: pointer;

      svg {
        width: 12px;
        height: 12px;
      }
    }
  }
}

body {
  margin: 0;
  font-family: $baseFont;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // background: #0e0f12 !important;
  background: #181b1f !important;
  color: $white;

  &.lightTheme {
    background-color: #fafbfc !important;
    background-repeat: no-repeat;
    background-size: cover;

    .successToast {
      box-shadow: 0px 1px 1px rgba(9, 13, 20, 0.06),
        -4px -4px 8px rgba(255, 255, 255, 0.4),
        8px 8px 24px rgba(9, 13, 20, 0.06);
    }
  }

  #root {
    > div {
      &:first-child {
        > div {
          top: 60px !important;
          right: 95px !important;
          transition: all 01s ease-in-out;

          @media (max-width: 1199px) {
            right: 0px !important;
          }

          @media (max-width: 991px) {
            top: 60px !important;
          }

          @media (max-width: 767px) {
            top: 50px !important;
          }
        }
      }
    }

    .go4109123758 {
      top: 60px !important;
      right: 95px !important;
      transition: all 01s ease-in-out;

      @media (max-width: 1199px) {
        right: 0px !important;
      }

      @media (max-width: 991px) {
        top: 60px !important;
      }

      @media (max-width: 767px) {
        top: 50px !important;
      }
    }
  }
}

button,
input,
optgroup,
select,
textarea {
  font-family: $baseFont;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $baseFont;
}

p {
  font-family: $baseFont;
}

ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
  font-family: $baseFont;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  height: 100%;
  display: flex;
  flex-direction: column;

  .ant-layout-content {
    flex: 1 0 auto;
  }
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.justify-content-center {
  justify-content: center !important;
}

.d-md-flex {
  @media (min-width: 768px) {
    display: flex !important;
  }
}

img {
  max-width: 100%;
}

body:not(.home-page) {
  padding-top: 96px;

  @media (max-width: 1199px) {
    padding-top: 70px;
  }

  @media (max-width: 767px) {
    padding-top: 62px;
  }
}

#w3a-container {
  position: relative;
  z-index: 9999;
}

.container {
  width: 100%;
  max-width: 1320px;
  margin: 0 auto;
  padding: 0 15px;

  &.fluid {
    max-width: unset;
    padding: 0px;
  }
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector,
.ant-select-focused:not(.ant-select-disabled).ant-select:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  border-color: #9c42f5;
}

.w-100 {
  width: 100% !important;
}

.ant-radio-group {
  .ant-radio-wrapper {
    @include theme() {
      color: theme-get("formLabelColor");
    }
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  // -webkit-box-shadow: 0 0 0 30px #252525 inset !important;
  // transition: background-color 5000s ease-in-out 0s;
  // -webkit-background-clip: text;
  -webkit-transition: "color 9999s ease-out, background-color 9999s ease-out";
  -webkit-transition-delay: 9999s;
}

.ant-picker-date-panel .ant-picker-content th {
  @include theme() {
    color: theme-get("white");
  }
}

.fv-form,
.Common_Form_design {
  .ant-input-group-wrapper {
    .ant-input-wrapper {
      @include theme() {
        background: theme-get("cardBg");
        color: theme-get("white");
        border: 1px solid theme-get("cardBg");
      }

      border-radius: 12px;
      min-height: 48px;
      padding: 0 0 0 16px;
      word-break: break-all;
      width: 100%;
      display: flex;
      align-items: center;

      &:hover,
      &:active,
      &:focus {
        box-shadow: 0 0 0 6px rgba(156, 66, 245, 0.12) !important;

        @include theme() {
          outline: 2px solid theme-get("navActive");
        }
      }

      .ant-input-group-addon {
        font-size: 16px;
        font-weight: 500;
        padding: 0px;
        background-color: transparent;
        border: 0px solid;
        display: inline-table;

        @include theme() {
          color: theme-get("white");
        }
      }

      .ant-input-affix-wrapper {
        box-shadow: none !important;
        outline: 0px solid transparent !important;
        .ant-input {
          padding-left: 0px;

          @include theme() {
            background: transparent !important;
            color: theme-get("white");
            border: 1px solid transparent;
          }
        }

        .ant-input-focused,
        .ant-input-number-focused,
        .ant-input:focus {
          box-shadow: none !important;
          outline: 0px solid transparent !important;
        }
      }
    }
  }
  .ant-form-item-label {
    label {
      @include theme() {
        color: theme-get("formLabelColor");
      }

      max-width: 400px;
      width: 100%;
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
      height: auto;
    }
  }

  .ant-select-single:not(.ant-select-customize-input) {
    &.ant-select-status-error {
      .ant-select-selector {
        @include theme() {
          background: theme-get("inputWrapperBg") !important;
          border-color: theme-get("inputWrapperBorder") !important;
        }
      }
    }

    .ant-select-selector {
      @include theme() {
        background: theme-get("inputWrapperBg") !important;
        border-color: theme-get("inputWrapperBorder");
      }

      border-radius: 12px;
      min-height: 48px;
      padding: 0px;

      .ant-select-selection-search {
        left: 0px;
      }

      .ant-select-selection-placeholder {
        display: flex;
        align-items: center;
        padding-left: 20px;
      }

      .ant-select-selection-search,
      .ant-select-selection-item {
        display: flex;
        align-items: center;
        padding-left: 20px;

        @include theme() {
          color: theme-get("inputTextColor");
        }
      }
    }
  }

  .ant-picker {
    @include theme() {
      background: theme-get("inputWrapperBg");
      border-color: theme-get("inputWrapperBorder");
    }

    &.ant-picker-status-error.ant-picker,
    &.ant-picker-status-error.ant-picker:not([disabled]):hover {
      @include theme() {
        background: theme-get("inputWrapperBg");
        border-color: theme-get("inputWrapperBorder");
      }
    }

    width: 100%;
    border-radius: 12px;
    padding: 7px 10px;

    .ant-picker-input {
      box-sizing: border-box;
      margin: 0;
      font-variant: tabular-nums;
      list-style: none;
      font-feature-settings: "tnum";
      min-width: 0;
      padding: 4px 11px;
      font-size: 14px;
      line-height: 1.5715;
      background-color: transparent;
      background-image: none;
      border: 1px solid transparent;
      border-radius: 2px;
      transition: all 0.3s;

      .ant-picker-suffix,
      input {
        @include theme() {
          color: theme-get("inputTextColor");
        }
      }
    }
  }

  .ant-input-number-affix-wrapper {
    @include theme() {
      background: theme-get("inputWrapperBg");
      border-color: theme-get("inputWrapperBorder");
    }

    width: 100%;
    border-radius: 12px;
    padding: 7px 10px;

    .ant-input-number {
      background-color: transparent;

      .ant-input-number-input {
        box-sizing: border-box;
        margin: 0;
        font-variant: tabular-nums;
        list-style: none;
        font-feature-settings: "tnum";
        position: relative;
        display: inline-block;
        width: 100%;
        min-width: 0;
        padding: 4px 11px;
        border-radius: 12px;

        @include theme() {
          color: theme-get("inputTextColor");
        }

        font-size: 14px;
        line-height: 1.5715;
        background-color: transparent;
        background-image: none;
        border: 1px solid transparent;
        border-radius: 2px;
        transition: all 0.3s;
      }

      .ant-input-number-handler-wrap {
        display: none;
      }
    }
  }

  .ant-form-item-label
    > label.ant-form-item-required:not(
      .ant-form-item-required-mark-optional
    ):before {
    display: none;
  }

  .ant-btn-primary[disabled],
  .ant-btn-primary[disabled]:active,
  .ant-btn-primary[disabled]:focus,
  .ant-btn-primary[disabled]:hover {
    @include theme() {
      color: theme-get("white");
    }
  }

  .ant-input-affix-wrapper {
    background: transparent !important;
    border: none;
    border-radius: 0;
    padding: 0;

    &.ant-input-affix-wrapper-focused,
    &.ant-input-affix-wrapper:focus,
    &:focus {
      // box-shadow: 0 0 0 2px rgb(255 24 237 / 20%);
      border-radius: 12px;
      box-shadow: 0 0 0 6px rgba(156, 66, 245, 0.12);

      @include theme() {
        outline: 2px solid theme-get("navActive");
      }
    }

    .ant-input {
      padding: 10px 16px;
    }
  }

  .ant-form-item-feedback-icon-success {
    .anticon-check-circle {
      svg {
        width: 16.3px;
        height: 16.3px;

        @include theme() {
          path {
            fill: theme-get("inputCheckCircle");
          }
        }
      }
    }
  }

  .ant-input {
    border-radius: 12px;
    min-height: 48px;
    padding: 10px 16px;
    font-size: 16px;
    word-break: break-all;

    @include theme() {
      background: theme-get("cardBg") !important;
      color: theme-get("white") !important;
      border: 1px solid theme-get("cardBg");
    }

    &:hover {
      @include theme() {
        background: theme-get("cardBg");
        color: theme-get("white");
        border: 1px solid theme-get("cardBg");
      }
    }

    &::placeholder {
      @include theme() {
        color: theme-get("navText");
      }
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      transition: background-color 5000s ease-in-out 0s;

      @include theme() {
        -webkit-box-shadow: 0 0 0px 1000px theme-get("cardBg");
        -webkit-text-fill-color: theme-get("white");
      }
    }
  }

  .ant-input-suffix {
    display: none;
  }

  &.form-Pass {
    .ant-input-suffix {
      display: block;
      position: absolute;
      right: 16px;
      top: 13px;
    }

    .ant-input {
      padding-right: 40px;
    }
  }

  .ant-input-focused,
  .ant-input:focus {
    box-shadow: 0 0 0 2px rgb(255 24 237 / 20%);
  }

  .ant-checkbox-wrapper {
    @include theme() {
      color: theme-get("inputCheckLable");
    }

    a {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;

      @include theme() {
        color: theme-get("inputCheckLable");
        border-bottom: theme-get("inputCheckLable");
      }
    }

    .ant-checkbox-inner {
      width: 24px;
      height: 24px;

      @include theme() {
        background: theme-get("cardBg");
        border: 1px solid theme-get("cardBg");
        box-shadow: 0 0 0 2px rgb(255 24 237 / 20%);
      }

      border-radius: 4px;

      &:after {
        height: 14.142857px;
        left: 6px;
      }
    }

    .ant-checkbox {
      top: 0.5em;
    }

    .ant-checkbox-checked {
      &:after {
        border: 0px;
      }

      .ant-checkbox-inner {
        @include theme() {
          background: theme-get("checkBoxCheckedBg");
        }

        border: 0px;
      }
    }
  }

  p.alreadyAccount {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;

    @include theme() {
      color: theme-get("alreadyText");
    }

    a {
      font-weight: 500;

      @include theme() {
        color: theme-get("alreadyTextLink");
      }

      text-decoration: underline;
      transition: all 0.25s;

      &:hover {
        text-decoration: none;
      }
    }
  }
}

.ant-select.customSelect {
  .ant-select-selector {
    @include theme() {
      background: theme-get("slidebtn") !important;
      color: theme-get("white");
      border-color: theme-get("slidebtn") !important;
    }

    border-radius: 48px;
    height: 48px;
    padding: 9px 15px;
    font-weight: 500;
    font-size: 16px;
  }

  .ant-select-arrow {
    svg {
      path {
        @include theme() {
          fill: theme-get("white");
        }
      }
    }
  }

  .ant-select-selection-placeholder {
    @include theme() {
      color: theme-get("white");
    }
  }
}

.ant-select-arrow {
  svg {
    path {
      @include theme() {
        fill: theme-get("white");
      }
    }
  }
}

.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  @include theme() {
    background: theme-get("creatorInfoSocialBg");
    border-color: theme-get("creatorInfoSocialBg");
  }
}

.ant-select-dropdown {
  @include theme() {
    background: theme-get("creatorInfoSocialBg");
    border-color: theme-get("creatorInfoSocialBg");
    box-shadow: theme-get("creatorInfoSocialBgShadow");
  }

  .ant-select-item-option-content {
    color: #fff;
  }

  .rc-virtual-list-holder {
    .rc-virtual-list-holder-inner {
      .ant-select-item {
        position: relative;

        &:before {
          content: "";
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          width: 100%;
          z-index: 0;
          transition: all 0.9s ease-in-out;
          opacity: 0;
        }

        .ant-select-item-option-state {
          display: none;
        }

        .ant-select-item-option-content {
          position: relative;
          z-index: 1;
          font-weight: 600;

          @include theme() {
            color: theme-get("credentialDetailButtonText") !important;
          }
        }

        &.ant-select-item-option-selected {
          background: transparent;

          &::before {
            transition: all 0.9s ease-in-out;
            opacity: 0.3;

            @include theme() {
              background: theme-get("buttonBg") !important;
            }
          }
        }

        &.ant-select-item-option-active {
          &::before {
            transition: all 0.9s ease-in-out;
            opacity: 0.3;

            @include theme() {
              background: theme-get("buttonBg") !important;
            }
          }
        }
      }
    }
  }
}

// perfect scrollbar
// .scrollbar-container {
//   padding: 0 10px !important;
//   .ps__thumb-y {
//     @include theme() {
//       background-color: theme-get("inputTextColor") !important;
//     }
//     width: 3px !important;
//   }
//   .ps__rail-y {
//     width: 7px !important;
//   }
// }

.ps .ps__rail-x:hover,
.ps .ps__rail-y:hover,
.ps .ps__rail-x:focus,
.ps .ps__rail-y:focus,
.ps .ps__rail-x.ps--clicking,
.ps .ps__rail-y.ps--clicking {
  background: transparent !important;
  border-radius: 8px;
  width: 6px !important;
}

.ps__thumb-x {
  @include theme() {
    background-color: theme-get("navText") !important;
  }

  height: 3px !important;
}

.ps__rail-y:hover > .ps__thumb-y,
.ps__rail-y:focus > .ps__thumb-y,
.ps__rail-y.ps--clicking .ps__thumb-y {
  @include theme() {
    background-color: theme-get("navText") !important;
  }

  width: 3px !important;
}

.ps__rail-x:hover > .ps__thumb-x,
.ps__rail-x:focus > .ps__thumb-x,
.ps__rail-x.ps--clicking .ps__thumb-x {
  @include theme() {
    background-color: theme-get("navText") !important;
  }

  height: 3px !important;
}

.sub_title {
  h2 {
    font-size: 36px;
    font-weight: 700;
    line-height: 40px;
    margin-bottom: 8px;

    @include theme() {
      color: theme-get("navTextActive");
    }

    @media (max-width: 767px) {
      font-size: 25px;
      line-height: 30px;
    }
  }

  p {
    font-size: 16px;
    font-weight: 500;

    @media (max-width: 767px) {
      font-size: 14px;
    }

    @include theme() {
      color: theme-get("navText");
    }
  }
}

h3 {
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;

  @include theme() {
    color: theme-get("white");
  }

  @media (max-width: 767px) {
    font-size: 18px;
  }
}

h4 {
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;

  @include theme() {
    color: theme-get("white");
  }

  @media (max-width: 767px) {
    font-size: 17px;
  }
}

h5 {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;

  @include theme() {
    color: theme-get("white");
  }
}

.pageNotFound {
  padding: 50px 0;
}

.ant-select-dropdown-empty {
  .ant-select-item-empty {
    .ant-empty {
      .ant-empty-description {
        @include theme() {
          color: theme-get("alreadyText") !important;
        }
      }
    }
  }
}

.ant-modal-root {
  .ant-modal-wrap {
    .ant-modal-content {
      @include theme() {
        background-color: theme-get("bgSection");
      }

      box-shadow: 0px 0px 10px rgb(9 13 20 / 6%),
        -1px 0px 6px rgb(255 255 255 / 40%), 8px 9px 20px rgb(9 13 20 / 6%);
      backdrop-filter: blur(108.731px);
      border-radius: 40px;

      .ant-modal-body {
        padding: 0px 20px;

        .ant-modal-confirm-body-wrapper {
          .ant-modal-confirm-body {
            padding: 30px 13px 20px;

            .ant-modal-confirm-title {
              @include theme() {
                color: theme-get("white");
              }
            }
          }

          .ant-modal-confirm-btns {
            margin-top: 10px;
            padding: 0 10px 18px;
            display: flex;
            align-items: center;

            .ant-btn {
              width: 100%;
            }
          }
        }
      }
    }
  }
}

.iconColor {
  @include theme() {
    stroke: theme-get("navText");
  }
}

.ant-empty {
  .ant-empty-description {
    @include theme() {
      color: theme-get("white");
    }
  }
}

.ant-picker-dropdown {
  .ant-picker-panel-container {
    @include theme() {
      background: theme-get("dropdownGradient") !important;
      color: theme-get("white");
      border-color: theme-get("cardBorder") !important;
    }

    .ant-picker-header {
      @include theme() {
        border-color: theme-get("cardBorder") !important;
      }
    }

    .ant-picker-cell-in-view.ant-picker-cell-in-range:before {
      @include theme() {
        background: theme-get("cardBorder") !important;
      }
    }

    .ant-picker-cell {
      @include theme() {
        color: theme-get("navIconColor");
      }
    }

    .ant-picker-cell-in-view,
    .ant-picker-header button {
      @include theme() {
        color: theme-get("white");
      }
    }

    .ant-picker-panel {
      border-bottom: 0;
    }

    .ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner,
    .ant-picker-cell:hover:not(.ant-picker-cell-selected):not(
        .ant-picker-cell-range-start
      ):not(.ant-picker-cell-range-end):not(
        .ant-picker-cell-range-hover-start
      ):not(.ant-picker-cell-range-hover-end)
      .ant-picker-cell-inner {
      @include theme() {
        background: theme-get("cardBg") !important;
      }
    }

    .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner,
    .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
    .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
      @include theme() {
        background: theme-get("navActive");
      }

      color: $white;
    }
  }
}

.ant-picker {
  .ant-picker-range-separator,
  .ant-picker-suffix {
    .anticon {
      @include theme() {
        color: theme-get("white");
      }
    }
  }
}

.sortingBar {
  @media (max-width: 767px) {
    margin-top: 10px;
  }

  .ant-picker {
    &.ant-picker-borderless {
      box-shadow: none !important;
      // margin-left: 15px;

      @include theme() {
        background: theme-get("slidebtn") !important;
        color: theme-get("white");
        border-color: theme-get("slidebtn") !important;
      }

      border-radius: 48px;
      height: 48px;
      padding: 9px 15px;
      font-weight: 600;
      font-size: 16px;

      @media (max-width: 767px) {
        width: 100%;
      }
    }

    .ant-picker-input > input {
      @include theme() {
        color: theme-get("white");
      }
    }

    .ant-picker-suffix {
      @include theme() {
        color: theme-get("white");
      }

      svg {
        @include theme() {
          fill: theme-get("white");
        }

        path {
          @include theme() {
            fill: theme-get("white");
          }
        }
      }
    }
  }
}

.ant-pagination {
  justify-content: center;
  display: flex;
  margin-top: 50px;

  .ant-pagination-prev,
  .ant-pagination-next {
    .ant-pagination-item-link {
      @include theme() {
        background: theme-get("cardBg");
        color: theme-get("white");
        border-color: theme-get("Border1");
      }
    }
  }

  .ant-pagination-item {
    @include theme() {
      background: theme-get("cardBg");
      color: theme-get("white");
      border-color: theme-get("Border1");
    }

    a {
      @include theme() {
        color: theme-get("navActive");
      }
    }

    &:hover,
    &.ant-pagination-item-active {
      @include theme() {
        background: theme-get("navActive");
        border-color: theme-get("navActive");
        color: theme-get("white");
      }

      a {
        color: $white;
        // @include theme() {
        //   color: theme-get("white");
        // }
      }
    }
  }
}

.d-flex {
  display: flex !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.align-items-center {
  align-items: center !important;
}

video {
  width: 100%;
  border-radius: 12px;
}

.mt-32 {
  margin-top: 32px;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.ant-input-number {
  background-color: transparent !important;
  width: 100%;
  border-radius: 12px;

  @include theme() {
    border: 1px solid theme-get("cardBg") !important;
  }

  .ant-input-number-input {
    box-sizing: border-box;
    margin: 0;
    font-variant: tabular-nums;
    list-style: none;
    font-feature-settings: "tnum";
    position: relative;
    display: inline-block;
    width: 100%;
    min-width: 0;
    padding: 10px 15px;
    height: auto;
    border-radius: 12px;

    @include theme() {
      color: theme-get("inputTextColor");
    }

    font-size: 14px;
    line-height: 1.5715;
    background-color: transparent;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 2px;
    transition: all 0.3s;
  }

  .ant-input-number-handler-wrap {
    display: none;
  }
}

.ant-switch {
  @include theme() {
    background: theme-get("switchtoggleGradient");
  }

  z-index: 2;
  background-image: none;
  min-width: 40px;
  height: 24px;
  line-height: 24px;

  &.ant-switch-checked {
    @include theme() {
      background: theme-get("heighlightText");
    }
  }

  .ant-switch-handle {
    width: 19px;
    height: 19px;

    &::before {
      @include theme() {
        background-color: theme-get("bgSection");
      }
    }
  }

  &:focus {
    box-shadow: unset;
  }

  // .ant-switch-handle {
  //   left: calc(100% - 21px);
  // }
}

.Common_Dropdown,
.ant-dropdown-menu {
  background: $blurbg;
  box-shadow: 0px 1px 1px rgba(9, 13, 20, 0.4),
    -4px -4px 8px rgba(224, 224, 255, 0.04), 8px 8px 24px rgba(9, 13, 20, 0.4);
  border-radius: 25px;
  width: 257px;
  padding: 20px;
  backdrop-filter: blur(108.731px);

  .ant-dropdown-menu-item {
    background-color: transparent;
    padding: 0px;

    .ant-dropdown-menu-title-content {
      a {
        display: inline-block;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        padding: 10px 10px;

        @include theme() {
          color: theme-get("paragraph");
        }

        span {
          margin-right: 15px;
        }

        &:hover,
        &:focus {
          opacity: 0.6;
        }

        svg {
          path {
            @include theme() {
              fill: theme-get("navText");
            }
          }
        }
      }
    }
  }
}

.lightTheme {
  .globalUrl {
    span {
      img {
        filter: brightness(1) invert(1);
      }
    }
  }

  .dash_inner_box .dash_heading .heading img {
    filter: brightness(1) invert(1);
  }
}

.anticon.anticon-minus-circle.dynamic-delete-button {
  @include theme() {
    color: theme-get("white");
  }
}

.globalUrl {
  position: relative;

  .ant-input {
    padding-right: 40px;
  }

  span {
    position: absolute;
    top: 12px;
    right: 16px;
  }
}

.ant-select {
  &.ant-select-focused {
    .ant-select-selector {
      box-shadow: 0 0 0 6px rgba(156, 66, 245, 0.12) !important;

      @include theme() {
        outline: 2px solid theme-get("navActive") !important;
      }
    }
  }
}

.ant-input-number-input {
  border-radius: 12px !important;
}

.ant-input-number {
  @include theme() {
    background: theme-get("cardBg") !important;
    color: theme-get("white");
    border: 1px solid theme-get("cardBg");
  }
}

.ant-input-focused,
.ant-input-number-focused,
.ant-input:focus {
  box-shadow: 0 0 0 2px rgb(255 24 237 / 20%) !important;
}
