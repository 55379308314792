@import "../../../assets/styles/_var.scss";
@import "../../../assets/styles/_mixins.scss";

.ant-drawer.ant-drawer-left.ant-drawer-open.header--drawer {
  z-index: 999;
}

.withoutImageWrap {
  height: 47px;
  width: 47px;
  border-radius: 100%;
  color: $white;
  display: flex;
  align-items: center;
  justify-content: center;

  @include theme() {
    background: theme-get("buttonBg");
  }

  p {
    display: inline-block;
    margin-bottom: 0px;
    font-size: 26px;
    font-weight: 500;
    text-transform: uppercase;
    line-height: normal;
  }
}

.fan-header.ant-layout-header {
  @include theme() {
    background: theme-get("cardGradient");
    box-shadow: theme-get("dropdownshadow1"), theme-get("dropdownshadow2"), theme-get("dropdownshadow3");
  }

  backdrop-filter: blur(108.731px);
  height: 96px;
  display: flex;
  align-items: center;
  padding-left: 39px;
  padding-right: 0px;
  z-index: 2;
  top: 0;
  position: fixed;
  width: 100%;

  @media (max-width: 1600px) {
    padding-left: 15px;
    padding-right: 15px;
  }

  @media (max-width: 1199px) {
    justify-content: space-between;
    padding: 10px 15px;
    height: 70px;
  }

  @media (max-width: 767px) {
    background: transparent !important;
    box-shadow: none;
  }

  @media (max-width: 420px) {
    align-items: center;
    // flex-direction: column;
    height: auto;
  }

  .fan-logo {
    min-width: 131px;
    height: 42px;
    margin-right: 50px;

    @media (max-width: 1600px) {
      margin-right: 15px;
    }

    @media (max-width: 767px) {
      min-width: auto;
      max-width: 90px;

      svg {
        width: 100%;
      }
    }

    // .fanverse_logo {
    //   path {
    //     @include theme() {
    //       fill: theme-get("paragraph");
    //     }
    //   }
    // }
  }

  .fan-header-sub {
    width: 100%;
    flex-wrap: nowrap;
    // @media (max-width: 1199px) {
    //   position: fixed;
    //   @include theme() {
    //     background: theme-get("dark");
    //   }
    //   backdrop-filter: blur(108.731px);
    //   top: 72px;
    //   width: 90% !important;
    //   height: calc(100vh - 72px);
    //   left: -100%;
    //   transition: all ease-in-out 0.3s;
    //   opacity: 0;
    //   visibility: hidden;
    //   display: flex;
    //   flex-direction: column;
    //   justify-content: flex-start;
    //   align-items: flex-start;
    // }

    @media (max-width: 420px) {
      justify-content: center;
    }

    &.mobileNav {
      left: 0;
      opacity: 1;
      visibility: visible;
    }

    >.ant-col {
      &:last-child {
        .ant-menu {
          li {
            margin-right: 0px;
          }
        }
      }

      &:nth-child(1) {
        flex: 1;

        // @media (max-width: 1199px) {
        //   width: 100%;
        // }
        .ant-menu,
        .ant-menu-submenu {
          background-color: transparent;
          border-bottom: 0;
          border-right: 0;
          // flex: 1;
          height: 100%;
          display: flex;

          // @media (max-width: 1199px) {
          //   display: block;
          //   height: auto;
          // }
          li {
            padding-left: 2px;
            padding-right: 2px;
            height: 100%;
            justify-content: center;
            display: flex;
            align-items: center;
            margin-right: 30px;
            top: 0;
            margin-top: 0;

            @media (max-width: 1600px) {
              margin-right: 15px;
            }

            @media (max-width: 1199px) {
              margin-right: 0px;
              height: 50px;
              justify-content: flex-start;
            }

            &.ant-menu-item-selected,
            &.ant-menu-item-selected:focus {
              background-color: transparent;
            }

            &.ant-menu-item {
              &:active {
                background: transparent;
              }
            }

            .ant-menu-title-content,
            a {
              font-size: 16px;

              @include theme() {
                color: theme-get("navText");
              }

              text-align: center;
              display: flex;
              font-weight: 600;
              align-items: center;
              justify-content: center;

              @media (max-width: 1600px) {
                margin-left: 5px;
              }

              &.active {
                @include theme() {
                  color: theme-get("navTextActive");
                }

                .fv-icon {
                  svg {
                    path {
                      @include theme() {
                        fill: theme-get("navActive");
                      }
                    }
                  }
                }

                &::before {
                  top: auto;
                  bottom: 0px;

                  @include theme() {
                    background-color: theme-get("navActive");
                  }

                  width: 100%;
                  height: 2px;

                  @media (max-width: 1600px) {
                    bottom: 0px;
                  }
                }
              }

              .fv-icon {
                padding-right: 10px;

                svg {
                  display: inline-block;
                  width: 20px;
                  height: 17px;
                  margin-top: -4px;
                  vertical-align: middle;

                  path {
                    @include theme() {
                      fill: theme-get("navIconColor");
                    }
                  }
                }
              }
            }

            &::after {
              right: 0px;
              left: 5px;
              border: 2px solid transparent;
            }

            &:hover,
            &.ant-menu-item-active {
              &::after {
                // @include theme() {
                border-bottom-color: transparent;
                // }
                right: 0px;
                left: 5px;
              }

              .ant-menu-title-content,
              a {
                @include theme() {
                  color: theme-get("navTextActive");
                }

                .fv-icon {
                  svg {
                    path {
                      @include theme() {
                        fill: theme-get("navActive");
                      }
                    }
                  }
                }
              }
            }

            &.ant-menu-submenu {
              &:hover {
                &::after {
                  @include theme() {
                    border-bottom-color: theme-get("navActive") !important;
                  }
                }
              }
            }

            &.ant-menu-submenu-selected {
              &::after {
                @include theme() {
                  border-bottom-color: theme-get("navActive");
                }
              }
            }

            .ant-menu-submenu-title {
              font-size: 16px;
              text-align: center;
              display: flex;
              font-weight: 600;
              align-items: center;
              justify-content: center;

              .ant-menu-title-content {
                padding-left: 10px;
              }

              svg {
                display: inline-block;
                width: 20px;
                height: 17px;
                margin-top: -4px;
                vertical-align: middle;

                path {
                  @include theme() {
                    fill: theme-get("navIconColor");
                  }
                }
              }

              .ant-menu-submenu-arrow {
                display: block;
                position: relative;

                @include theme() {
                  color: theme-get("navText");
                }

                top: 0;
                right: 0;
                transform: rotate(90deg);
                padding-bottom: 20px;
                padding-right: 9px;
              }
            }
          }
        }
      }

      &:nth-child(2) {
        display: flex;
        align-items: center;

        // @media (max-width: 1199px) {
        //   display: block;
        //   width: auto;
        //   margin-left: 15px;
        // }
        .themeButtonStyle {
          width: 48px;
          min-width: 48px;
          height: 48px;

          @include theme() {
            background-color: theme-get("inputfield");
          }

          border-radius: 48px;
          border: 0px;
          position: relative;
          margin-left: 10px;
          margin-right: 10px;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;

          @media (max-width: 767px) {
            width: 36px;
            min-width: 36px;
            height: 36px;
          }

          svg {
            @media (max-width: 767px) {
              width: 15px;
              height: 15px;
            }

            path {
              @include theme() {
                fill: theme-get("textGray60");
              }
            }
          }
        }

        .btn-add-wrap {
          // width: 96px;
          // height: 96px;
          background: rgba(224, 224, 255, 0.02);
          display: flex;
          justify-content: flex-end;
          align-items: center;
          padding: 13px 0px;

          @media (max-width: 1600px) {
            padding: 13px 0px;
          }

          // @media (max-width: 1199px) {
          //   width: 70px;
          // }
          &.loginWrap {
            // width: 100%;
            padding-right: 25px;
            padding-left: 25px;
            height: 96px;

            @media (max-width: 1515px) {
              padding-right: 15px;
              padding-left: 15px;
            }

            @media (max-width: 767px) {
              padding: 0 5px;
            }

            .profileWrap {
              display: flex;
              align-items: center;
              justify-content: center;
              padding-right: 25px;

              @media (max-width: 1600px) {
                padding-right: 10px;
              }

              img {
                width: 42px;
                height: 42px;
                display: inline-block;
                border-radius: 100%;
                overflow: hidden;
                font-size: 0;
                background: linear-gradient(135deg,
                    #ff00aa 0%,
                    #9c42f5 50.18%,
                    #5d2de1 100%);

                @media (max-width: 1600px) {
                  width: 40px;
                  height: 40px;
                }
              }

              .profileText {
                margin-left: 15px;
                display: flex;
                flex-direction: column;

                @media (max-width: 1199px) {
                  width: 50%;
                }

                .profileBalance {
                  display: flex;
                  align-items: center;

                  p {
                    font-size: 13px;
                    padding-top: 5px;
                    padding-right: 10px;
                    display: flex;
                  }
                }

                p {
                  line-height: normal;
                  margin-bottom: 0;
                  font-weight: 600;
                  font-size: 18px;
                  display: inline-block;
                  text-transform: capitalize;

                  // align-items: center;
                  @include theme() {
                    color: theme-get("mintLike");
                  }

                  max-width: 200px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;

                  @media (max-width: 1600px) {
                    max-width: 140px;
                    font-weight: 400;
                    font-size: 15px;
                  }

                  span {
                    padding-right: 5px;
                  }
                }
              }
            }

            .loginBtn {
              padding: 10px;
              width: 108px;
              height: 47px;

              @media (max-width: 1299px) {
                padding: 11px 15px;
              }

              @media (max-width: 767px) {
                padding: 9px 18px;
                // font-size: 11px;
              }

              .ant-spin-spinning {
                color: $white;
              }

              &:hover {
                &:disabled {
                  .ant-spin-spinning {
                    color: $darktext;
                  }
                }
              }
            }
          }

          .btn-add {
            width: 48px;
            height: 48px;

            @include theme() {
              background: theme-get("buttonBg");
            }

            box-shadow: 0px 0px 1px rgba(156, 66, 245, 0.24),
            0px 2px 4px -1px rgba(27, 10, 82, 0.12),
            0px 16px 24px rgba(156, 66, 245, 0.24),
            0px 8px 8px -4px rgba(156, 66, 245, 0.12);
            border: 0px;
            border-radius: 48%;
            position: relative;
            cursor: pointer;

            &:before {
              position: absolute;
              content: "";
              width: 13px;
              height: 13px;
              // background: url(../../../assets/images/icon_add.svg) no-repeat;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
            }

            &:hover {
              background: $buttonBgHover;
            }
          }
        }
      }
    }

    &.mobileNav {
      .menu_toggle {
        .menu_icon {
          height: 3px;
          width: 15px;
          left: 5px;
          top: calc(50% - 2px);
          transform: rotate(-45deg);
          background-color: red;

          &::after {
            width: 15px;
            height: 3px;
            top: -1px;
            transform: rotate(-270deg);
            background-color: yellow;
          }

          &::before {
            transform: scale(0);
          }
        }
      }
    }

    .menu_icon,
    .menu_icon::after {
      transition: all 0.3s linear;
    }
  }
}

.ant-drawer-header-title {
  justify-content: flex-end;

  .ant-drawer-close {
    margin-right: 10px;
    height: 36px;
    min-width: 36px;
    background: transparent;
    border-radius: 50%;
    padding: 0;
    box-shadow: 6px 10px 43px rgb(9 13 20 / 0), 30px 30px 80px rgb(224 224 255 / 2%), 0 0 25px rgb(9 13 20 / 10%);
    border: 0;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0px;
  }

  svg {
    fill: $darktext;
  }
}

.ant-drawer-wrapper-body .ant-drawer-header {
  @include theme() {
    border-bottom: 1px solid theme-get("cardBorder");
  }
}

.darkTheme {
  .ant-drawer-wrapper-body {
    background: $dropdownGradient;
  }

  .ant-drawer-content,
  .ant-drawer-header,
  .ant-menu {
    background: transparent;
    // background-color: $bgSection;

  }

  .ant-menu-item a,
  .ant-menu-title-content {
    color: $white;
  }

  .ant-drawer-header-title {
    .ant-drawer-close {
      box-shadow: 0px 1px 1px rgb(9 13 20 / 40%), -4px -4px 8px rgb(224 224 255 / 4%), 8px 8px 24px rgb(9 13 20 / 40%);
    }

    svg {
      fill: $white;
    }
  }

  .lightthemeIcon {
    display: none;
  }
}

.lightTheme {
  .darkthemeIcon {
    display: none;
  }

  .menu_toggle {
    box-shadow: 0px 1px 1px rgba(9, 13, 20, 0.06),
      -4px -4px 8px rgba(255, 255, 255, 0.4), 8px 8px 24px rgba(9, 13, 20, 0.06);
  }
}

.menu_toggle {
  background: transparent;
  height: 50px;
  min-width: 50px;
  border-radius: 50%;
  padding: 0;
  box-shadow: 0px 1px 1px rgba(9, 13, 20, 0.4),
    -4px -4px 8px rgba(224, 224, 255, 0.04), 8px 8px 24px rgba(9, 13, 20, 0.4);
  border: 0;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 767px) {
    height: 36px;
    min-width: 36px;

    svg {
      width: 20px;
      height: 20px;
    }
  }

  .icon_left {
    transition-duration: 0.5s;
    position: absolute;
    height: 3px;
    width: 15px;
    top: 12px;

    @include theme() {
      background-color: theme-get("navText");
    }

    left: 5px;

    &:hover {
      cursor: pointer;
    }

    &:before {
      transition-duration: 0.5s;
      position: absolute;
      width: 15px;
      height: 3px;

      @include theme() {
        background-color: theme-get("navText");
      }

      content: "";
      top: -9px;
      left: 0;
    }

    &:after {
      transition-duration: 0.5s;
      position: absolute;
      width: 15px;
      height: 3px;

      @include theme() {
        background-color: theme-get("navText");
      }

      content: "";
      top: 9px;
      left: 0;
    }
  }

  .icon_right {
    transition-duration: 0.5s;
    position: absolute;
    height: 3px;
    width: 15px;
    top: 12px;

    @include theme() {
      background-color: theme-get("navText");
    }

    left: 20px;

    &:before {
      transition-duration: 0.5s;
      position: absolute;
      width: 15px;
      height: 3px;

      @include theme() {
        background-color: theme-get("navText");
      }

      content: "";
      top: -9px;
      left: 0;
    }

    &:after {
      transition-duration: 0.5s;
      position: absolute;
      width: 15px;
      height: 3px;

      @include theme() {
        background-color: theme-get("navText");
      }

      content: "";
      top: 9px;
      left: 0;
    }
  }

  &.open {
    .icon_left {
      transition-duration: 0.5s;
      background: transparent;
      left: 0;

      &::before {
        transform: rotateZ(45deg) scaleX(1.4) translate(7px, -2px);
      }

      &::after {
        transform: rotateZ(-45deg) scaleX(1.4) translate(7px, 2px);
      }
    }

    .icon_right {
      transition-duration: 0.5s;
      background: transparent;
      left: 15px;

      &:before {
        transform: rotateZ(-45deg) scaleX(1.4) translate(-2px, 4px);
      }

      &:after {
        transform: rotateZ(45deg) scaleX(1.4) translate(-3px, -4px);
      }
    }
  }
}

.ant-menu-submenu {
  &.ant-menu-submenu-popup {
    min-width: 230px !important;

    // @include theme() {
    //   background: theme-get("bgSection") !important;
    // }
    @include theme() {
      background: theme-get("cardGradient");
      box-shadow: theme-get("dropdownshadow1"), theme-get("dropdownshadow2"), theme-get("dropdownshadow3");
    }

    backdrop-filter: blur(54px);
    border-radius: 24px;
    // box-shadow: 0px 1px 1px rgba(9, 13, 20, 0.4),
    // -4px -4px 8px rgba(224, 224, 255, 0.04),
    // 8px 8px 24px rgba(9, 13, 20, 0.4);
    overflow: hidden;

    .ant-menu {
      background: transparent;
      padding: 31px 27px;

      &.ant-menu-sub {
        .ant-menu-item {
          padding: 0px;
          height: unset;
          line-height: normal;
          padding-bottom: 20px;

          &:last-child {
            padding-bottom: 0px;
          }

          .ant-menu-title-content {
            a {
              font-weight: 600;
              font-size: 16px;
              line-height: 24px;

              @include theme() {
                color: theme-get("navTextActive");
              }

              display: flex;
              align-items: center;

              .fv-icon {
                margin-right: 20px;

                svg {
                  path {
                    @include theme() {
                      fill: theme-get("navActive");
                    }
                  }
                }
              }
            }
          }

          &.ant-menu-item-selected,
          &:active,
          &:focus {
            background-color: transparent;

            a {
              .fv-icon {
                svg {
                  path {
                    @include theme() {
                      fill: theme-get("navActive");
                    }
                  }
                }
              }

              @include theme() {
                color: theme-get("navTextActive");
              }
            }
          }
        }
      }
    }
  }
}

.btnOnlyForMobile {
  display: none;

  @media (max-width: 1199px) {
    display: flex;
  }
}

.hideMob {
  @media (max-width: 1199px) {
    display: none !important;
  }
}

.TwoBtnFix {
  display: flex;
  align-items: center;
}

.header--drawer {
  .loginBtn {
    padding: 10px;
    width: 108px;
    height: 47px;

    @media (max-width: 1299px) {
      padding: 11px 15px;
    }

    @media (max-width: 767px) {
      padding: 9px 18px;
      // font-size: 11px;
    }

    .ant-spin-spinning {
      color: $white;
    }

    &:hover {
      &:disabled {
        .ant-spin-spinning {
          color: $darktext;
        }
      }
    }
  }

  .fan-header-sub {
    position: relative;

    @media (max-width: 1199px) {
      // flex-direction: column;
      justify-content: space-between;
      height: auto;
      flex-direction: column;

      ul.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-light {
        border: none;
      }
    }
  }

  .TwoBtnFix {
    display: flex;
    position: absolute;
    bottom: 2px;
    right: 0px;
    align-items: center;
    justify-content: flex-end;
    z-index: 3;

    .notification_drop {
      margin-left: 15px;
      margin-right: 0px;

      .notificationDropdown {
        box-shadow: 0px 4px 7px rgb(9 13 20 / 10%);
        background-color: transparent;
        width: 47px;
        height: 47px;

        .ant-space {
          .ant-space-item {
            svg {
              @include theme() {
                fill: theme-get("formLabelColor");
              }
            }
          }
        }
      }
    }

    .themeButtonStyle {
      box-shadow: 0px 4px 7px rgb(9 13 20 / 10%);
      background-color: transparent;
      border-radius: 50%;
      border: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px;
      width: 47px;
      height: 47px;
      z-index: 11;
    }
  }

  .profileWrap {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    img {
      width: 50px;
      height: 50px;
      border-radius: 100px;
      margin-right: 10px;
    }

    color: #000;

    .profileText {
      display: flex;
      flex-direction: column;

      p {
        font-weight: 600;
      }
    }

    .profileBalance,
    .profileText {
      p {
        line-height: normal;
        margin-bottom: 0;
        display: inline-block;
        max-width: 275px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-transform: capitalize;

        @include theme() {
          color: theme-get("navText");
        }
      }
    }

    .profileBalance {
      p {
        margin-top: 5px;

        span {
          vertical-align: middle;
          padding-right: 4px;
        }
      }

      @media (max-width: 1199px) {
        display: flex;
        align-items: center;

        p {
          margin-right: 8px;

          span {
            vertical-align: text-top;
          }
        }
      }
    }

    @media (max-width: 1199px) {
      margin: 20px 0;

      img {
        width: 42px;
        height: 42px;
      }
    }
  }

  .ant-menu {
    .ant-menu-item {
      margin-bottom: 5px;
      padding: 0 !important;

      .ant-menu-title-content {
        a {
          display: flex;
          align-items: center;
          padding: 0 10px;
        }
      }

      &-selected,
      &-selected a,
      &-selected a:hover {
        color: $orange;
        font-weight: 500;
        background-color: rgba(255, 141, 35, 0.1);

        &:after {
          border-color: $orange;
        }
      }

      .fv-icon {
        display: inline-block;
        margin-right: 5px;

        svg {
          path {
            @include theme() {
              fill: theme-get("navText");
            }
          }
        }
      }
    }

    .ant-menu-submenu {
      .ant-menu-submenu-title {
        padding: 0 10px !important;

        @media (max-width: 1199px) {
          background: transparent;
        }

        &:hover {
          color: $orange;
        }

        .ant-menu-submenu-arrow {
          color: $orange;
        }
      }

      svg {
        margin-right: 5px;

        path {
          @include theme() {
            fill: theme-get("navText");
          }
        }
      }
    }
  }
}

.notification_drop {
  margin-right: 25px;

  @media (max-width: 1299px) {
    margin-right: 15px;
  }

  .notificationDropdown {
    @include theme() {
      background: theme-get("cardBg");
    }

    width: 48px;
    height: 48px;
    border-radius: 100px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: rgba(224, 224, 255, 0.6);
    font-size: 18px;
    position: relative;
  }

  span.anticon.anticon-bell {
    @include theme() {
      color: theme-get("navText");
    }
  }

  span.noti_number {
    position: absolute;
    top: -2px;
    right: -10px;

    @include theme() {
      background-color: theme-get("navActive");
    }

    @include theme() {
      color: $white;
    }

    width: 27px;
    height: 19px;
    display: inline-block;
    border-radius: 35px;
    font-weight: 600;
    font-size: 13px;
    line-height: 17px;
    text-align: center;
  }
}

.profile_droplist {
  display: inline-flex;
  align-items: center;

  @media (max-width: 1365px) {
    min-width: 84px;
  }

  @media (max-width: 1199px) {
    padding-right: 10px;
    width: 50%;
  }

  .profileDropdown {
    @include theme() {
      background: theme-get("cardBg");
    }

    border-radius: 100px;
    padding: 0;
    padding-right: 25px;
    display: inline-flex;
    align-items: center;
    height: 47px;

    .profileImg {
      object-fit: cover;
      object-position: top;
    }

    .ant-space {
      padding-left: 20px;
    }

    .anticon.anticon-down {
      @include theme() {
        color: theme-get("navText");
      }
    }

    @media (max-width: 1365px) {
      padding-right: 15px;

      .ant-space {
        padding-left: 15px;
      }
    }
  }
}