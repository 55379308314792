$baseFont: 'Montserrat', sans-serif;
$bgSection:#212429;
$pageNotFoundText:#fff;
$textGray60:rgba(224, 224, 255, 0.6);
$textGray1:rgba(224, 224, 255, 1);
$white:rgba(255, 255, 255, 1);
$heighlightText:#9C42F5;
$footerLinkColor:#E0E0FF;
$footerHoverColor:#9C42F5;
$buttonBg:linear-gradient(135deg, #FF00AA 0%, #9C42F5 50.18%, #5D2DE1 100%);
$buttonBgHover:linear-gradient(135deg, #b6057b 0%, #8617f5 50.18%, #470ee2 100%);
$navText:rgba(224, 224, 255, 0.6);
$navTextActive:rgba(255, 255, 255, 1);
$navActive:rgba(156, 66, 245, 1);
$navIconColor:rgba(224, 224, 255, 0.4);
$cardGradient: linear-gradient(135deg, rgba(245, 247, 250, 0.12) 0%, rgba(245, 247, 250, 0.06) 52.14%, rgba(245, 247, 250, 0.0001) 100%);
$cardBorder:rgba(245, 247, 250, 0.06);
$collectionText:#E0E0FF;
$bgSlickArrow:#2D2F33;
$darktext: #141029;
$inputfield: rgba(224, 224, 255, 0.02);
$danger: #F54562;
$dangerBG: #F5456208;
$orange: #ff8d23;
$cardboxshadow1: 0px 1px 1px rgba(9, 13, 20, 0.06), -4px -4px 8px rgba(255, 255, 255, 0.4), 8px 8px 24px rgba(9, 13, 20, 0.06);
$cardboxshadow2: 0px 1px 1px rgba(9, 13, 20, 0.4), -4px -4px 8px rgba(224, 224, 255, 0.04), 8px 8px 24px rgba(9, 13, 20, 0.4);
$Border1:rgba(20, 16, 41, 0.12);
$dropdownGradient: linear-gradient(to left top, #32343a, #2b2e34, #25272e, #1e2128, #181b22);
$blurbg:linear-gradient(135deg, rgba(245, 247, 250, 0.12) 0%, rgba(245, 247, 250, 0.06) 52.14%, rgba(245, 247, 250, 0.0001) 100%);

$green:#00B8B9;
$skypeblue:#00BBFF;
$purple:#9C42F5;
$magenta:#FF00AA;
$musdard:#FF5E00;
$yellow:#FFAB00;
$pink:#F54562;
$red:#F54562;
$parrot:#00FFAA;
// "increment_value" : #00FFAA, 


$themes: (
  darkTheme: (
    "bgSection": #212429,
    "blurbg":linear-gradient(135deg, rgba(245, 247, 250, 0.12) 0%, rgba(245, 247, 250, 0.06) 52.14%, rgba(245, 247, 250, 0.0001) 100%),
    "footerInput":rgba(224, 224, 255, 0.04),
    "loaderbgSection": rgba(0, 0, 0, 0.6),
    "cardGradient" :  linear-gradient(135deg, rgba(245, 247, 250, 0.12) 0%, rgba(245, 247, 250, 0.06) 52.14%, rgba(245, 247, 250, 0.0001) 100%),
    "cardBorder":rgba(245, 247, 250, 0.06),
    "dropdownGradient":  linear-gradient(to left top, #32343a, #2b2e34, #25272e, #1e2128, #181b22),
    "cardboxshadow": 0px 1px 1px rgba(9, 13, 20, 0.4),
    "secGradient1":linear-gradient(135deg, rgba(101,18,77,1) 0%,rgba(229,229,229,0) 100%),
    "secGradient2" :linear-gradient(to right, rgba(61,37,90,0) 0%,rgba(61,37,90,1) 100%),
    "secGradient3" :linear-gradient(to right, rgba(81,20,65,1) 0%,rgba(61,37,90,0) 100%),
    "Border1":rgba(224, 224, 255, 0.12),
    "navText" :  rgba(224, 224, 255, 0.6),
    "navTextActive" :  rgba(255, 255, 255, 1),
    "navIconColor" :rgba(224, 224, 255, 0.4),
    "navActive" : rgba(156, 66, 245, 1),
    "heighlightText": #9C42F5,
    "footerLinkColor": #E0E0FF,  
    "inputfield" : rgba(156, 66, 245, 0.03),
    "textGray60" : rgba(224, 224, 255, 0.6),
    "paragraph" : #fff,
    "buttonBg" : linear-gradient(135deg, #FF00AA 0%, #9C42F5 50.18%, #5D2DE1 100%),
    "textGray1" :rgba(224, 224, 255, 1),
    "titleColor" : rgba(255, 255, 255, 1),
    "dark" : #0E0F12,
    "slidebtn" : rgba(45, 47, 51, 1),
    "footerBg" : rgba(9, 13, 20, 0.24),
    "cardBg" : rgba(224, 224, 255, 0.02),
    "increment_view" : rgba(0, 255, 170, 0.03),
    "decrement_view" : rgba(245, 69, 98, 0.03),
    "increment_value" : #00FFAA,
    "filterimg" : invert(0),
    "signUpCardBg":linear-gradient(135deg, rgba(245, 247, 250, 0.12) 0%, rgba(245, 247, 250, 0.06) 52.14%, rgba(245, 247, 250, 0.0001) 100%),
    "signUpCardBorderColor" : rgba(245, 247, 250, 0.06),
    "signUpCardShadow" : 0px 1px 1px rgba(9, 13, 20, 0.4),
    "signUpCardFilter": blur(108.731px),
    "signUpTitleColor":#fff,
    "signUpSubTitleColor":#fff,
    "singUpTabsNavWrap":linear-gradient(180deg, #090D14 0%, #282A2E 52.98%, #2D2F33 100%),
    "singUpTabsNavWrapShadow":"inset 0px 1px 1px rgba(9, 13, 20, 0.4), inset -4px -4px 8px rgba(224, 224, 255, 0.04), inset 8px 8px 40px rgba(9, 13, 20, 0.4)",
    "singUpTabsColor":#fff,
    "singUpTabsActiveColor":#fff,
    "singUpTabsIconColor":rgba(224, 224, 255, 0.6),
    "singUpTabsIconActiveColor":#fff,
    "singUpTabsActiveBg":linear-gradient(135deg, #2D2F33 0%, #282A2E 51.37%, #212429 99.14%),
    "singUpTabsActiveShadow":"0px 1px 1px rgba(9, 13, 20, 0.4), -4px -4px 8px rgba(224, 224, 255, 0.04), 8px 8px 24px rgba(9, 13, 20, 0.4)",
    "thanksSubTitle":rgba(224, 224, 255, 0.6),
    "resendText":rgba(156, 66, 245, 1),
    "thanksIconBg":rgba(156, 66, 245, 0.03),
    "thanksIconColor":rgb(156, 66, 245),
    "formLabelColor":rgba(224, 224, 255, 0.6),
    "inputWrapperBg":rgba(224, 224, 255, 0.02),
    "inputWrapperBorder":rgba(224, 224, 255, 0.02),
    "inputTextColor":#fff,
    "inputCheckCircle":#9c42f5,
    "inputCheckLable":rgba(224, 224, 255, 0.6),
    "checkBoxCheckedBg":#9c42f5,
    "alreadyText":#FFFFFF,
    "alreadyTextLink":rgba(156, 66, 245, 1),
    "creatorBg": linear-gradient(135deg,  rgba(255,0,170,1) 0%,rgba(156,66,245,1) 50%,rgba(93,45,225,1) 100%),
    "creatorInfoBg":rgba(224, 224, 255, 0.02),
    "creatorInfoSocialBg":#282A2E,
    "creatorInfoSocialBgShadow" :"0px 0px 1px rgba(9, 13, 20, 0.4), 0px 2px 4px -1px rgba(9, 13, 20, 0.24), 0px 8px 8px -4px rgba(9, 13, 20, 0.24)",
    "socialIcons": rgba(224, 224, 255, 0.6),
    "credentialDetail":#fff,
    "credentialDetailH3Span":rgba(224, 224, 255, 0.6),
    "credentialDetailH3Strong":#FFFFFF,
    "credentialDetailDescription":rgba(224, 224, 255, 0.6),
    "credentialDetailFollowColor":#fff,
    "credentialDetailFollowColorSpan":rgba(224, 224, 255, 0.6),
    "credentialDetailButtonFollow":linear-gradient(135deg, #FF00AA 0%, #9C42F5 50.18%, #5D2DE1 100%),
    "credentialDetailButtonFollowShadow":"0px 0px 1px rgba(156, 66, 245, 0.24), 0px 2px 4px -1px rgba(27, 10, 82, 0.12), 0px 16px 24px rgba(156, 66, 245, 0.24), 0px 8px 8px -4px rgba(156, 66, 245, 0.12)",
    "kycButtonBackGround":#2D2F33,
    "credentialDetailButtonMessage":#2D2F33,
    "credentialDetailButtonMessageShadow":none,
    "credentialDetailButtonText":#fff,
    "tabSepratorBg":rgba(224, 224, 255, 0.12), 
    "cancelbtn": rgba(245, 247, 250, 0.06),
    "mintCollectionH2":#fff,
    "mintCollectionH3":rgba(224, 224, 255, 0.6),
    "mintCollectionH3Strong":#FFFFFF,
    "mintLike":#FFFFFF,
    "mintIcon":#FFFFFF,
    "mintDetails": #E0E0FF,
    "mintDetailsh3": #fff,
    "mintDetailsh3Icon": #fff,
    "lightshadebtn": rgba(156, 66, 245, 0.03),
    "textGradient":linear-gradient(90deg, #FFFFFF 0%, #FF00AA 33.65%, #9C42F5 69.06%, #5D2DE1 100%),
    "white":#ffffff,
    "switchtoggleGradient":linear-gradient(180deg, #090D14 0%, #282A2E 52.98%, #2D2F33 100%),
    "dropdownBg":#2D2F33,
    "dropdownBgHover" : rgba(224, 224, 255, 0.02),
    "btnboxshadow" : rgba(107, 107, 255, 0.2),
    "btnboxshadow1" : 0px 0px 1px rgba(156, 66, 245, 0.24),
    "btnboxshadow2" : 0px 2px 4px -1px rgba(27, 10, 82, 0.12),
    "btnboxshadow3" : 0px 16px 24px rgba(156, 66, 245, 0.24),
    "btnboxshadow4" : 0px 8px 8px -4px rgba(156, 66, 245, 0.12),
    "headerbgshadow1":0px 1px 1px rgba(9, 13, 20, 0.4),
    "headerbgshadow2":-4px -4px 8px rgba(224, 224, 255, 0.04),
    "headerbgshadow3": 8px 8px 24px rgba(9, 13, 20, 0.4),
    "headersearchbg" : rgba(224, 224, 255, 0.02),
    "dropdownshadow1" : 0px 1px 1px rgba(9, 13, 20, 0.4),
    "dropdownshadow2" : -4px -4px 8px rgba(224, 224, 255, 0.04),
    "dropdownshadow3" : 8px 8px 24px rgba(9, 13, 20, 0.4),
    "commonsearchbg" : #1c1e23,
    "dashedborder":rgba(224, 224, 255, 0.24),
    "toastBg": linear-gradient(135deg, rgba(245, 247, 250, 0.12) 0%, rgba(245, 247, 250, 0.06) 52.14%, rgba(245, 247, 250, 0.0001) 100%),
    "newbtnng": rgba(224, 224, 255, 0.04),
    "bannerhaeding":#ffffff,
    "headerbg": linear-gradient(135deg, rgba(245, 247, 250, 0.12) 0%, rgba(245, 247, 250, 0.06) 52.14%, rgba(245, 247, 250, 0.0001) 100%),
  ),
  lightTheme: (
    "bannerhaeding":rgba(20,16,41,0.8),
    "newbtnng": rgba(224, 224, 255, 0.04),
    "commonsearchbg" : #f0f2f6,    
    "dashedborder":rgba(20, 16, 41, 0.24),
    "dropdownshadow1" : 0px 1px 1px rgba(9, 13, 20, 0.06),
    "dropdownshadow2" : -4px -4px 8px rgba(255, 255, 255, 0.4),
    "dropdownshadow3" : 8px 8px 24px rgba(9, 13, 20, 0.06),
    "toastBg":linear-gradient(135deg, #FFFFFF 0%, #FAFBFC 51.82%, #F5F7FA 100%),
    "headersearchbg" : rgba(20, 16, 41, 0.02),
    "headerbg":linear-gradient(135deg, #FFFFFF 0%, #FAFBFC 51.82%, #F5F7FA 100%),
    "headerbgshadow1": 0px 1px 1px rgba(9, 13, 20, 0.06),
    "headerbgshadow2":-4px -4px 8px rgba(255, 255, 255, 0.4),
    "headerbgshadow3": 8px 8px 24px rgba(9, 13, 20, 0.06),
    "btnboxshadow" : rgba(27, 10, 82, 0.12),
    "btnboxshadow1": 0px 0px 1px rgba(255, 141, 35, 0.24),
    "btnboxshadow2": 0px 2px 4px -1px rgba(27, 10, 82, 0.12),
    "btnboxshadow3": 0px 8px 8px -4px rgba(255, 141, 35, 0.12),
    "dropdownBg": linear-gradient(135deg, #FFFFFF 0%, #FAFBFC 51.82%, #F5F7FA 100%),
    "dropdownBgHover" : rgba(20, 16, 41, 0.02),
    "white":rgba(20, 16, 41, 0.8),
    "bgSection": #FAFBFC,
    "footerInput":rgba(20, 16, 41, 0.04),
    "loaderbgSection": rgba(0, 0, 0, 0.6),
    "blurbg":linear-gradient(135deg, rgba(245, 247, 250, 0.12) 0%, rgba(245, 247, 250, 0.06) 52.14%, rgba(245, 247, 250, 0.0001) 100%),
    "dropdownGradient": linear-gradient(to left top, #ffffff, #fdfdfe, #fafbfc, #f8f9fb, #f5f7fa),
    "cardGradient" : linear-gradient(135deg, rgba(255, 255, 255, 0.8) 0%, rgba(250, 251, 252, 0.6) 51.82%, rgba(245, 247, 250, 0.4) 100%),
    "cardBorder":rgba(33, 36, 41, 0.06),
    "Border1":rgba(20, 16, 41, 0.12),
    "cardboxshadow": 0px 1px 1px rgba(9, 13, 20, 0.06),
    "navText" :  rgba(20, 16, 41, 0.6),
    "navTextActive" :  rgba(20, 16, 41, 1),
    "navIconColor" :rgba(20, 16, 41, 0.4),
    "navActive" : rgba(255, 141, 35, 1),
    "heighlightText": #FF8D23,
    "footerLinkColor": #141029,
    "inputfield" : rgba(20, 16, 41, 0.035),
    "textGray60" : rgba(20, 16, 41, 0.8),
    "paragraph" : #141029,
    "buttonBg" : #FF8D23,
    "textGray1" :rgba(224, 224, 255, 1),
    "titleColor" : rgba(20, 16, 41, 0.8),
    "dark" : #fff,
    "slidebtn" : rgba(235, 239, 245, 0.6),
    "footerBg" : rgba(235, 239, 245, 0.6),
    "cardBg" : rgba(20, 16, 41, 0.02),
    "increment_view" : rgba(0, 184, 185, 0.03),
    "decrement_view" : rgba(245, 69, 98, 0.03),
    "increment_value" : #00B8B9,
    "filterimg" : invert(1),
    "signUpCardBg":linear-gradient(135deg, rgba(255, 255, 255, 0.8) 0%, rgba(250, 251, 252, 0.6) 51.82%, rgba(245, 247, 250, 0.4) 100%),
    "signUpCardBorderColor":rgba(33, 36, 41, 0.06),
    "signUpCardShadow":0px 1px 1px rgba(9, 13, 20, 0.06),
    "signUpCardFilter":blur(108.731px),
    "signUpTitleColor":#000,
    "signUpSubTitleColor":#000,
    "singUpTabsNavWrap": linear-gradient(180deg, #EBEFF5 0%, #F5F7FA 52.98%, #FFFFFF 100%),
    "singUpTabsNavWrapShadow":"inset 0px 1px 1px rgba(9, 13, 20, 0.06), inset -4px -4px 8px rgba(255, 255, 255, 0.4), inset 8px 8px 40px rgba(9, 13, 20, 0.06)",
    "singUpTabsColor":rgba(20, 16, 41, 1),
    "singUpTabsActiveColor":rgba(20, 16, 41, 1),
    "singUpTabsIconColor": rgba(20, 16, 41, 0.6),
    "singUpTabsIconActiveColor":rgba(20, 16, 41, 1),
    "singUpTabsActiveBg":linear-gradient(180deg, rgba(255, 255, 255, 0.8) 0%, rgba(250, 251, 252, 0.6) 51.82%, rgba(245, 247, 250, 0.4) 100%),
    "singUpTabsActiveShadow":"0px 1px 1px rgba(9, 13, 20, 0.06), -4px -4px 8px rgba(255, 255, 255, 0.4), 8px 8px 24px rgba(9, 13, 20, 0.06)",
    "thanksSubTitle":rgba(20, 16, 41, 0.6),
    "cancelbtn": #d8dde4,
    "resendText":rgba(255, 141, 35, 1),
    "thanksIconBg":rgba(156, 66, 245, 0.03),
    "thanksIconColor":rgba(255, 141, 35, 1),
    "formLabelColor":rgba(20, 16, 41  , 0.6),
    "inputWrapperBg":rgba(20, 16, 41, 0.02),
    "inputWrapperBorder":rgba(20, 16, 41, 0.02),
    "inputTextColor":rgba(20, 16, 41, 1),
    "inputCheckCircle":rgba(255, 141, 35, 1),
    "inputCheckLable":rgba(20, 16, 41, 1),
    "checkBoxCheckedBg":rgba(255, 141, 35, 1),
    "alreadyText":rgba(20, 16, 41, 1),
    "alreadyTextLink":rgba(255, 141, 35, 1),
    "creatorBg":#FF8D23,
    "creatorInfoBg":rgba(20, 16, 41, 0.02),
    "creatorInfoSocialBg":#fff,
    "creatorInfoSocialBgShadow" :"0px 0px 1px rgba(20, 16, 41, 0.12), 0px 2px 4px -1px rgba(20, 16, 41, 0.06), 0px 8px 8px -4px rgba(20, 16, 41, 0.06)",
    "socialIcons":rgba(20, 16, 41, 0.6),
    "credentialDetail":rgba(20, 16, 41, 0.8),
    "credentialDetailH3Span":rgba(20, 16, 41, 0.6),
    "credentialDetailH3Strong":rgba(20, 16, 41, 0.8),
    "credentialDetailDescription":rgba(20, 16, 41, 0.6),
    "credentialDetailFollowColor":rgba(20, 16, 41, 0.8),
    "credentialDetailFollowColorSpan":rgba(20, 16, 41, 0.6),
    "credentialDetailButtonFollow":#FF8D23,
    "credentialDetailButtonFollowShadow":"0px 0px 1px rgba(255, 141, 35, 0.24), 0px 2px 4px -1px rgba(27, 10, 82, 0.12), 0px 8px 8px -4px rgba(255, 141, 35, 0.12)",
    "credentialDetailButtonMessage":#fff,
    "kycButtonBackGround":rgba(20, 16, 41, 0.02),
    "credentialDetailButtonMessageShadow":"0px 0px 1px rgba(20, 16, 41, 0.12), 0px 2px 4px -1px rgba(20, 16, 41, 0.06), 0px 8px 8px -4px rgba(20, 16, 41, 0.06)",
    "credentialDetailButtonText":rgba(20, 16, 41, 0.8),
    "tabSepratorBg":rgba(20, 16, 41, 0.12),
    "mintCollectionH2":#141029,
    "mintCollectionH3":rgba(20, 16, 41, 0.6),
    "mintCollectionH3Strong":#141029,
    "mintLike":#141029,
    "mintIcon":rgba(20,16,41,0.4),
    "mintDetails":rgba(20,16,41,0.6),
    "mintDetailsh3": #141029,
    "mintDetailsh3Icon": #141029,
    "lightshadebtn": rgba(255, 141, 35, 0.03),
    "textGradient":linear-gradient(90deg, #FFDC96 0%, #FFAB00 33.65%, #FF8D23 69.06%, #F54562 100%),
    "switchtoggleGradient":linear-gradient(180deg, #EBEFF5 0%, #F5F7FA 52.98%, #FFFFFF 100%),
  ),
);

