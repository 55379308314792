@import "../../../assets/styles/_var.scss";
@import "../../../assets/styles/_mixins.scss";

.ant-btn-primary,
.ant-btn-primary,
.ant-btn-default {
  @include theme {
    background: theme-get("buttonBg");
  }
  box-shadow: 0px 0px 1px rgba(156, 66, 245, 0.24),
    0px 2px 4px -1px rgba(27, 10, 82, 0.12),
    0px 16px 24px rgba(156, 66, 245, 0.24),
    0px 8px 8px -4px rgba(156, 66, 245, 0.12);
  border-radius: 24px;
  border: 0px;
  padding: 12px 24px;
  font-size: 16px;
  font-weight: 600;
  height: auto;
  transition: all 0.25s;
  color: $white;

  &:hover {
    background: $buttonBgHover;
    color: $white;
  }

  // &:hover,
  // &:focus {
  //   background: $buttonBgHover;
  //   color: $white;
  // }
  &.ant-btn-loading {
    @include theme {
      background: theme-get("cardBg");
      color: theme-get("white");
      box-shadow: none;
    }
  }
  &::before {
    display: none !important;
  }
  &:disabled {
    box-shadow: none;
    @include theme() {
      color: theme-get("white") !important;
      background: theme-get("kycButtonBackGround") !important;
    }
  }
  // &:disabled {
  //   @include theme() {
  //     color: theme-get("white") !important;
  //     background: theme-get("kycButtonBackGround") !important;
  //   }
  // }
}
.ant-btn-danger {
  background: $red;
  @include theme {
    color: theme-get(white);
  }
  border-radius: 24px;
  border: 0px;
  padding: 5px 20px;
  font-size: 14px;
  font-weight: 600;
  height: auto;
  position: relative;
  transition: all 0.5s ease-in-out;
  &:hover,
  &:focus {
    background: $red;
    @include theme {
      color: theme-get(white);
    }
  }
}
.ant-btn-success {
  background: $parrot;
  color: $bgSection;
  border-radius: 24px;
  border: 0px;
  padding: 5px 20px;
  font-size: 14px;
  font-weight: 600;
  height: auto;
  position: relative;
  transition: all 0.5s ease-in-out;
  @include theme {
    color: theme-get(white);
  }
  &:hover,
  &:focus {
    transition: all 0.5s ease-in-out;
    background: $parrot;
    color: $bgSection;
  }
}

.ant-btn-secondary {
  @include theme {
    background: theme-get(footerBg);
    color: theme-get(navTextActive);
  }
  border-radius: 24px;
  border: 0px;
  padding: 12px 24px;
  font-size: 16px;
  font-weight: 600;
  height: auto;
  transition: all 0.25s;
  &:hover,
  &:focus {
    @include theme {
      background: theme-get(cancelbtn);
      color: theme-get(navTextActive);
    }
  }
}

.grey_btn {
  @include theme {
    background: theme-get("credentialDetailButtonMessage");
    color: theme-get(navTextActive);
  }
  border-radius: 24px;
  border: 0px;
  padding: 12px 24px;
  font-size: 16px;
  font-weight: 600;
  height: auto;
  transition: all 0.25s;
  box-shadow: none;
  &:hover,
  &:focus {
    @include theme {
      background: theme-get(cancelbtn);
      color: theme-get(navTextActive);
    }
  }
}
